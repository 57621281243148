var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { attrs: { id: "splash", role: "status", "aria-busy": "true" } },
        [
          _c("div", { staticClass: "loading-splash-screen" }, [
            _c(
              "div",
              { staticClass: "loading-infobox" },
              [_vm._m(0), _c("loading-spinner")],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading-logo" }, [
      _c("figure", [
        _c("img", {
          staticClass: "loading-image",
          attrs: {
            src: require("assets/images/taide-art-animated.svg"),
            alt: "taide.art is loading...",
            "aria-busy": "true",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }