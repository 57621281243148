/*
  *  ~ Vue 2 init ~
*      Entry point to load the main App and splash screen.
 *
  ***************************************************************/
import 'core-js/stable/index.js';        //  ~ polyfills ~
import 'regenerator-runtime/runtime.js'; //    See `babel.config.js`.
import Vue from 'vue';

export const init = async () => {

    // splash loading screen
    await import(
        /* webpackPreload: true */
        /* webpackMode: "eager" */
        /* webpackChunkName: "splash" */
        '@/splash.js')
        .then(loader => Vue.use(loader.default))
        .catch(console.error);

    // main App
    await import(
        /* webpackChunkName: "app" */
        '@/main.js')
        .then(loader => Vue.use(loader.default))
        .catch(console.error);

};

export default init();
