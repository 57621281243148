/**
 * Splash loading screen
 */
import Vue from 'vue';

import Splash from '@/views/Splash.vue';

export const splash = new Vue({
    el: '#splash',
    components: {
        Splash
    },
    template: '<Splash/>'
});

export default splash;
